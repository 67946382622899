<template>
  <div>
    <div>
      <v-row v-if="!recaptchaExist" justify="center" class="my-10">
        <v-progress-circular
          indeterminate
          :color="colors[colorIndex]"
          :width="7"
        ></v-progress-circular>
      </v-row>
    </div>
    <div class="g-recaptcha" data-sitekey="6Ldkm8woAAAAAEpVk16ZyiFyJPz6xkET37Cwyo5k"></div>
  </div>
</template>

<script>
export default {
  name: "GoogleRecaptcha",

  data: () => ({
    recaptchaExist: false,
    checkInterval: '',
    colorInterval: '',
    colors: ['grey', 'light-blue', 'light-blue darken-4', 'green'],
    colorIndex: 0
  }),

  mounted() {
    this.createRecaptchaScript()
    this.checkInterval = setInterval(() => {
      if (document.querySelectorAll('iframe').length > 1) {
        this.recaptchaExist = true
        clearInterval(this.colorInterval)
        clearInterval(this.checkInterval)
      }
    }, 0)
    this.colorInterval = setInterval(() => {
      this.colorIndex = (this.colorIndex + 1) % this.colors.length
    }, 777)
  },

  beforeDestroy() {
    if (this.checkInterval) {
      clearInterval(this.checkInterval)
    }
  }
}
</script>

<style scoped>

</style>
