<template>
  <v-card
    :loading="!this.$store.getters.ownerId.length"
    class="mx-auto my-12"
    max-width="374"
    elevation="0"
  >
    <v-form ref="form" v-model="valid" @submit.prevent="login" class="mt-15">
      <v-row justify="center">
        <v-col cols="12" sm="12">
          <v-text-field
            v-model="phone"
            :rules="phoneRules"
            type="text"
            label="Номер телефона"
            color="teal"
            required
            prepend-icon="mdi-phone"
            persistent-hint
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12">
          <v-text-field
            v-model="password"
            :rules="passwordRules"
            :append-icon="passwordType === 'password' ? 'mdi-eye' : 'mdi-eye-off'"
            @click:append="setPasswordType"
            :type="passwordType"
            label="Пароль"
            color="teal"
            required
            prepend-icon="mdi-lock"
          ></v-text-field>
        </v-col>

        <v-col cols="12" sm="12">
          <GoogleRecaptcha/>
        </v-col>

        <v-col cols="12" sm="12">
          <v-btn type="submit" color="teal" class="white--text">Войти</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-card>
</template>


<script>

import phoneFilter from "../../filters/phoneFilter"
import GoogleRecaptcha from "@/components/GoogleRecaptcha";

export default {
  name: "Login",
  components: {GoogleRecaptcha},
  data: () => ({
    phone: "",
    phoneRules: [
      v => !!v.length || "Заполните это поле",
      v => v.length === 12 || "Номер должен быть в формате +79*********"
    ],
    password: "",
    passwordRules: [
      v => !!v.length || "Заполните это поле",
    ],
    passwordType: "password",
    serverMessage: "",
    valid: true,
  }),

  methods: {
    validatePhoneNumber() {
      this.phone = phoneFilter(this.phone)
    },

    setPasswordType() {
      this.passwordType === "password" ? this.passwordType = "text" : this.passwordType = "password"
    },

    // captcha() {
    //   // eslint-disable-next-line
    //   setTimeout(console.log(grecaptcha.getResponse()),3000)
    //   // console.log(recaptcha)
    // },

    async login() {
      await this.$refs.form.validate()
      if (!this.valid) return

      // eslint-disable-next-line
      const recaptchaToken = grecaptcha.getResponse()
      if (!recaptchaToken){
        this.$store.commit('setServerMessage', 'Пожалуйста, подтвердите, что Вы не робот')
        return
      }

      const password = this.password
      const phone = this.phone

      let response = await fetch(`${this.$store.state.server}/auth/login`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: JSON.stringify({phone, password, recaptchaToken})
      })

      const json = await response.json()
      this.$store.commit("setServerMessage", json.message)

      if (response.status === 200) {
        localStorage.setItem("token", JSON.stringify(json.token))
        localStorage.setItem("phone", JSON.stringify(json.phone))
        localStorage.setItem("firstName", JSON.stringify(json.firstName))
        localStorage.setItem("lastName", JSON.stringify(json.lastName))
        localStorage.setItem("toMaster", JSON.stringify(json.settings.toMaster))
        localStorage.setItem("toClients", JSON.stringify(json.settings.toClients))

        await this.$router.push("/calendar")
      }
    }
  },

  mounted() {
  },

  watch: {
    phone: function () {
      this.phone = phoneFilter(this.phone)
    }
  }

}
</script>
